import { AsyncPipe } from '@angular/common';
import {
  Component,
  ElementRef,
  model,
  OnInit,
  ViewEncapsulation,
  inject,
  contentChild,
  viewChild,
} from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { ActivatedRoute } from '@angular/router';

import {
  combineLatest,
  distinctUntilChanged,
  map,
  Observable,
  shareReplay,
  takeUntil,
  tap,
} from 'rxjs';
import type { PartialDeep } from 'type-fest';

import { propertyStates } from '@alan-apps/api-interfaces';
import { Department, Property, User } from '@alan-apps/data-access';
import { AuthService } from '@nghedgehog/angular-ui';
import { useTakeUntilDestroyed } from '@nghedgehog/core';
import { TranslateModule } from '@ngx-translate/core';

import { OrderService } from '../../../service/order.service';
import { PropertyService } from '../../../service/property.service';
import { SystemService } from '../../../service/system.service';
import { SearchTypeNavComponent } from '../search-type-nav/search-type-nav.component';
import { BaseSearchPropertiesComponent } from './base-search-properties.component';
import { RentHouseComponent } from './rent-house/rent-house.component';
import { input } from '@angular/core';
import { output } from '@angular/core';

@Component({
  selector: 'app-search-properties-block',
  templateUrl: './search-properties-block.component.html',
  styleUrls: ['./search-properties-block.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    SearchTypeNavComponent,
    RentHouseComponent,
    MatIconModule,
    MatButtonModule,
    AsyncPipe,
    TranslateModule,
  ],
})
export class SearchPropertiesBlockComponent implements OnInit {
  private _order = inject(OrderService);
  private _fb = inject(FormBuilder);
  private _system = inject(SystemService);
  private _property = inject(PropertyService);
  private _route = inject(ActivatedRoute);
  private _auth = inject(AuthService);
  readonly takeUntilAppDestroy = useTakeUntilDestroyed();

  baseSearchProperties = contentChild(BaseSearchPropertiesComponent);
  formElm = viewChild<ElementRef<HTMLFormElement>>('formElm');
  rentHouseComponent = viewChild(RentHouseComponent);

  noInit = input(false);
  expanded = model(false);

  onlyInput = input(false);
  searchOptions = input({
    enabled: true,
  });

  member$ = input<Observable<PartialDeep<User>[]> | undefined>();
  department$ = input<Observable<Department[]> | undefined>();

  searchResult = input<PaginatorResult<Property[]> | undefined>();
  manageSearch = input(false);
  disableSellerSelect = input(false);

  doSearch = output<{
    classification: any;
    type: any;
  }>();
  doExport = output();
  doImport = output();

  showTypes: number[] = [];

  hasSearchDepPermission =
    this._auth.isSystemAdvance || this._auth.hasManagerPermission;

  hasSearchCreatorPermission =
    this._auth.isAdvance || this._auth.hasManagerPermission;

  hasSearchPermission =
    this._auth.hasManagerPermission ||
    this._auth.isAdvance ||
    this._auth.isMember;

  propertyStates = propertyStates;

  nameMap = {
    ages: '屋齡',
    areas: '面積',
    cities: '城市',
    communities: '社區',
    devices: '設備',
    districts: '鄉鎮',
    educationSchools: '學校',
    faces: '朝向',
    floors: '樓層',
    furnitures: '傢俱',
    kinds: '類型',
    lifeFunctions: '環境',
    liveAreas: '生活圈',
    parkingTypes: '車位',
    patternFeatures: '格局特點',
    patterns: '格局',
    pet: '寵物',
    prices: '金額',
    publicUtilities: '公設',
    shapes: '房型',
    shortest: '短期',
    smoke: '抽菸',
    styles: '風格',
    transportationFacilities: '交通',
    usages: '用途',
  };

  formGroup = this._fb.group({
    departmentId: [null],
    classification: null,
    category: [null, []],
    input: [null, []],
    type: [null, []],
    price: [null, []],
    area: [null, []],
    shape: [null, []],
    pattern: [null, []],
    kind: [null, []],
    cityId: [null, []],
    districtId: [null, []],
    mrtCategory: [null, []],
    mrt: [null, []],
    enable: [null, []],
    state: [null, []],
    headline: [null, []],
    featured: [null, []],
    sellerId: [null, []],
    cities: this._fb.array([]),
    districts: this._fb.array([]),
    transportationFacilities: this._fb.array([]),
    communities: this._fb.array([]),
    liveAreas: this._fb.array([]),
    educationSchools: this._fb.array([]),
    // 標的用途
    usages: this._fb.array([]),
    // 型態（公寓、電梯大樓
    shapes: this._fb.array([]),
    // 用途
    categories: this._fb.array([]),
    // 類型
    kinds: this._fb.array([]),
    prices: this._fb.array(this.getRentPriceArray()),
    priceFrom: ['', []],
    priceTo: ['', []],
    patternFeatures: this._fb.array([]),
    patterns: this._fb.array(
      [
        { name: '不限', value: null },
        { name: '1房', value: 1 },
        { name: '2房', value: 2 },
        { name: '3房', value: 3 },
        { name: '4房', value: 4 },
        { name: '5房及以上', value: '5_' },
      ].map((x) => this._system.createCheckedFormGroup(x)),
    ),
    styles: this._fb.array([]),
    areas: this._fb.array(
      [
        { name: '不限', value: null },
        { name: '10坪以下', value: '_10' },
        { name: '10-20坪', value: '10_20' },
        { name: '20 - 30坪', value: '20_30' },
        { name: '30 - 40坪', value: '30_40' },
        { name: '40 - 50坪', value: '40_50' },
        { name: '50坪以上', value: '50' },
      ].map((x) => this._system.createCheckedFormGroup(x)),
    ),
    ages: this._fb.array(
      [
        { name: '不限', value: null },
        { name: '5年內', value: '_5' },
        { name: '5 - 10年', value: '5_10' },
        { name: '10年以上', value: '10_' },
        { name: '20年以上', value: '20_' },
      ].map((x) => this._system.createCheckedFormGroup(x)),
    ),
    floors: this._fb.array(
      [
        { name: '不限', value: null },
        { name: '1 - 5樓', value: '1_5' },
        { name: '5 - 10樓', value: '5_10' },
        { name: '10樓以上', value: '10_' },
        { name: '15樓以上', value: '15_' },
      ].map((x) => this._system.createCheckedFormGroup(x)),
    ),
    spaceNumbers: this._fb.array([]),
    publicUtilities: this._fb.array([]),
    lifeFunctions: this._fb.array([]),
    parkingTypes: this._fb.array(
      [
        { name: '平面車位', value: '1' },
        { name: '機械車位', value: '2,3,4,5' },
      ].map((x) => this._system.createCheckedFormGroup(x)),
    ),
    devices: this._fb.array([]),
    furnitures: this._fb.array([]),
    faces: this._fb.array([]),
    pet: this._fb.array(
      [
        { name: '可養寵物', value: 'true' },
        { name: '不可養寵物', value: 'false' },
      ].map((x) => this._system.createCheckedFormGroup(x)),
    ),
    shortest: this._fb.array(
      [
        { name: '可短期', value: '1_6' },
        { name: '不可短期', value: '12_' },
      ].map((x) => this._system.createCheckedFormGroup(x)),
    ),
    smoke: this._fb.array(
      [
        { name: '可抽菸', value: 'true' },
        { name: '不可抽菸', value: 'false' },
      ].map((x) => this._system.createCheckedFormGroup(x)),
    ),
  });

  get isClearAll() {
    return this.formGroup.dirty || this.savedQueries.length > 0;
  }

  get input() {
    return this.formGroup.get('input');
  }
  get priceFrom() {
    return this.formGroup.get('priceFrom');
  }
  get priceTo() {
    return this.formGroup.get('priceTo');
  }

  get departmentId() {
    return this.formGroup.get('departmentId');
  }

  get type() {
    return this.formGroup.get('type');
  }

  get prices() {
    return this.formGroup.get('prices') as FormArray;
  }
  get category() {
    return this.formGroup.get('category');
  }
  get classification() {
    return this.formGroup.get('classification');
  }

  kinds$ = this._order.getKindsValue().pipe(
    map((kinds) => [{ title: '不限', value: null }, ...kinds]),
    shareReplay(1),
  );

  shapes$ = this._order.getShapesValue().pipe(
    map((kinds) => [{ title: '不限', value: null }, ...kinds]),
    shareReplay(1),
  );

  cities$ = this._system.getCities(true).pipe(shareReplay(1));

  queries$ = this.formGroup.valueChanges.pipe(
    map(() => {
      const queries = Object.entries(this.formGroup.getRawValue()).reduce(
        (acc, [key, value]) => {
          if (Array.isArray(value)) {
            value.forEach((x, i) => {
              if (x['checked']) {
                acc.push({
                  type: this.nameMap[key],
                  value: x,
                  delete: () => {
                    const itemControl = (
                      this.formGroup.get(key) as FormArray
                    ).at(i);

                    itemControl.patchValue({
                      ...itemControl.getRawValue(),
                      checked: false,
                    });
                  },
                });
              }
            });
          } else {
            if (value !== null && this.nameMap[key]) {
              acc.push({
                type: this.nameMap[key],
                value,
                delete: () => {
                  this.formGroup.get(key).patchValue(null);
                },
              });
            }
          }

          return acc;
        },
        [],
      );
      this.savedQueries = queries;
      return queries;
    }),
    shareReplay(1),
  );

  savedQueries = [];

  ngOnInit() {
    if (this.manageSearch()) {
      this.showTypes = [0, 1, 2];
    }
    if (this.disableSellerSelect()) {
      this.formGroup.get('sellerId').disable();
    }
    combineLatest([
      this._route.queryParams,
      this.kinds$,
      this.shapes$,
      this.cities$,
    ])
      .pipe(
        tap(([params]) => {
          Object.entries(params).forEach((curr) => {
            this._system.checkRouteParams(curr, this.formGroup);
            const [key, value] = curr;
            if (key === 'prices') {
              const sourceValues: string[] = value.split(',');
              const formPrices = this.prices.getRawValue();

              const fromToValue = sourceValues.find((value) =>
                formPrices.every((x) => x.id !== value),
              );

              if (!fromToValue) return;
              const [from, to] = fromToValue.split('_');

              this.priceFrom.patchValue(from);
              this.priceTo.patchValue(to);
            }
          });
          this.checkClassification();
        }),
        this.takeUntilAppDestroy(),
      )
      .subscribe();

    this.classification.valueChanges
      .pipe(
        distinctUntilChanged(),
        tap((classification) => {
          const routeClassification =
            this._route.snapshot.queryParamMap.get('classification');

          if (routeClassification !== classification) {
            this.checkClassification(classification);
            this.search();
          }
        }),
        this.takeUntilAppDestroy(),
      )
      .subscribe();

    this.departmentId.valueChanges
      .pipe(
        tap(() => {
          this.formGroup.get('sellerId').patchValue(null);
        }),
        this.takeUntilAppDestroy(),
      )
      .subscribe();
  }

  private checkClassification(
    classification = this.classification.getRawValue(),
  ) {
    if (classification === 1) {
      this.prices.clear({ emitEvent: false });
      const items = this.getSellPriceArray();

      items.forEach((item, i) => {
        this.prices.push(item, { emitEvent: i === items.length });
      });
    } else {
      this.prices.clear({ emitEvent: false });
      const items = this.getRentPriceArray();

      items.forEach((item, i) => {
        this.prices.push(item, { emitEvent: i === items.length });
      });
    }
  }

  private resetForm(emit: boolean) {
    const value = this.formGroup.getRawValue();

    const resetObject = Object.entries(value).reduce((acc, [key, value]) => {
      if (Array.isArray(value)) {
        value.forEach((x) => (x['checked'] = null));
        acc[key] = value;
      } else {
        acc[key] = null;
      }

      return acc;
    }, {});

    this.formGroup.reset(
      { ...resetObject, ...this.getCategory() },
      { emitEvent: emit },
    );
  }

  clear() {
    this.resetForm(true);

    this.doSearch.emit(this.getQueryParams());
  }

  search(redirect?: boolean) {
    const value = this.getQueryParams();
    if (redirect) {
      return this._property.goMore(value);
    }
    this.doSearch.emit(value);
  }

  getQueryParams() {
    const formValue = this.formGroup.getRawValue();

    const { priceFrom, priceTo, ...rest } = formValue;

    const queries = Object.entries(rest).reduce((acc, [key, value]) => {
      if (Array.isArray(value)) {
        const values = value.filter((x) => x['checked']);
        if (values.length > 0) {
          acc[key] = values.map((x) => x['id']).join(',');
        }
      } else {
        acc[key] = value;
      }

      return acc;
    }, {});

    queries['prices'] = [
      queries['prices'],
      priceFrom || priceTo ? `${priceFrom || ''}_${priceTo || ''}` : null,
    ]
      .filter((x) => !!x)
      .join(',');

    return {
      ...queries,
      ...this.getCategory(),
    };
  }

  toggleCollapse() {
    this.expanded.update((x) => !x);
  }

  private getCategory() {
    return {
      classification: this.classification.getRawValue(),
      type: this.type.value,
    };
  }

  private getRentPriceArray() {
    return [
      { name: '不限', value: null },
      { name: '5000元以下', value: '_5000' },
      { name: '5000-10000元', value: '5000_10000' },
      { name: '10000-20000元', value: '10000_20000' },
      { name: '20000-30000元', value: '20000_30000' },
      { name: '30000-40000元', value: '30000_40000' },
      { name: '40000-60000元', value: '40000_60000' },
      { name: '60000元以上', value: '60000' },
    ].map((x) => this._system.createCheckedFormGroup(x));
  }

  private getSellPriceArray() {
    return [
      { name: '不限', value: null },
      { name: '1000萬以下', value: '_1000' },
      { name: '1000 - 1500萬', value: '1000_1500' },
      { name: '1500 - 2000萬', value: '1500_2000' },
      { name: '2000 - 2500萬', value: '2000_2500' },
      { name: '2500 - 3000萬', value: '2500_3000' },
      { name: '3000 - 4000萬', value: '3000_4000' },
      { name: '4000萬以上', value: '4000' },
    ].map((x) => this._system.createCheckedFormGroup(x));
  }
}
